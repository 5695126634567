import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import GraphQLQueryBuilder from '../../../components/GraphQLQueryBuilder';
export const _frontmatter = {
  "product": "signatures",
  "category": "GraphQL",
  "sort": 1,
  "title": "Query Builder"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`createSignatureOrder`}</h2>
    <GraphQLQueryBuilder query="createSignatureOrder" mdxType="GraphQLQueryBuilder" />
    <h2>{`addSignatory`}</h2>
    <p>{`Execute the createSignatureOrder builder above to automatically get a signature order id.`}</p>
    <GraphQLQueryBuilder query="addSignatory" mdxType="GraphQLQueryBuilder" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      